import AdminSceneController from 'controllers/newsroom/adminSceneController';
import ReaderAdminEditController
    from 'controllers/newsroom/readerAdminEditController';
import ReaderPlaceholderView
    from 'views/newsroom/readerPlaceholderView';
import ReaderPresentationOptionsController
    from 'controllers/newsroom/readerPresentationOptionsController';
import vent from 'libraries/vent';
import readerConfigurationsInstance
    from 'collections/newsroom/configurationsInstance';
import EditReaderCategoryController
    from 'controllers/newsroom/editReaderCategoryController';
import ReaderArchivePatternController
    from 'controllers/newsroom/readerArchivePatternController';
import ReaderRefreshPatternController
    from 'controllers/newsroom/readerRefreshPatternController';
import ReaderNotificationConfigurationController
    from 'controllers/newsroom/readerNotificationConfigurationController';
import ReaderEmailSettingsController
    from 'controllers/newsroom/readerEmailSettingsController';
import ReaderAudienceController
    from 'controllers/newsroom/readerAudienceController';
import ReaderPrivacyController
    from 'controllers/newsroom/readerPrivacyController';
import _ from 'underscore';
import Notificator from 'libraries/notificator'
import I18n from 'i18n';
import React from 'react';
import ReactWrapper from 'views/marionetteReactWrapper';
import MediaInsightsDetail from 'views/newsroom/MediaInsightsDetail';

export default class AdminEditSceneController extends AdminSceneController {
    show() {
        this.controller = new ReaderAdminEditController({
            configurationId: this.options.configurationId
        });
        this.showInLeftColumn(this.controller.view);

        this._showPlaceHolder();
        this._registerListeners();
    }

    _registerListeners() {
        this.listenTo(this.controller,
                    'showPresentationOptions',
                    this.showPresentationOptions);
        this.listenTo(
            this.controller,
            'category-selected',
            this._showEditReaderCategory
        );
        this.listenTo(
            this.controller.view,
            'media_insight-selected',
            this._showEditMediaInsight
        );
        this.listenTo(
            this.controller, 'category-closed', this._showPlaceHolder
        );
        this.listenTo(
            this.controller,
            'reader-notification-configuration-closed',
            this._showPlaceHolder
        );
        this.listenTo(
            this.controller,
            'configuration:deleteStarted',
            () => {
              const placeholder = new ReaderPlaceholderView();
              this.showInRightColumn(placeholder);
            }
        );
        this.listenTo(
            this.controller,
            'reader-notification-configuration-selected',
            this._showEditReaderNotificationConfiguration
        );
        this.listenTo(
            this.controller, 'showArchivePattern', this.showArchivePattern
        );
        this.listenTo(
            this.controller, 'showRefreshPattern', this.showRefreshPattern
        );
        this.listenTo(
            this.controller, 'showEmailSettings', this.showEmailSettings
        );
        this.listenTo(
            this.controller, 'showAudience', this.showAudience
        );
        this.listenTo(
            this.controller, 'showAudiencePrivacy', this.showAudiencePrivacy
        );
    }

    rerender() {
      this.controller.view.render();
      this.controller.view.toggleAudiencePrivacyButton()
    }

    _showRightHandView(
        toggleButtonState,
        toggleButtonStateContext,
        ControllerClass,
        additionalOpts
    ) {
        this.configuration =
            readerConfigurationsInstance.get(this.options.configurationId);
        const options = _.extend({
            configuration: this.configuration
        }, additionalOpts || {});
        this.rightHandController =
            new ControllerClass(options);
        this.showInRightColumn(this.rightHandController.view);
        this.focusRight();
        this.listenTo(this.rightHandController.view, 'close', () => {
            toggleButtonState.call(toggleButtonStateContext, false);
        });
        toggleButtonState.call(toggleButtonStateContext, true);
        this.controller.deselectCategories();
        this.controller.deselectNotificationConfigurations();
    }

    showPresentationOptions() {
        this._showRightHandView(
            this.controller.view.setShowPresentationOptions,
            this.controller.view,
            ReaderPresentationOptionsController
        );
    }

    showArchivePattern() {
        this._showRightHandView(
            this.controller.view.toggleArchivePatternButton,
            this.controller.view,
            ReaderArchivePatternController
        );
    }

    showRefreshPattern() {
        this._showRightHandView(
            this.controller.view.toggleRefreshPatternButton,
            this.controller.view,
            ReaderRefreshPatternController
        );
    }

    showEmailSettings() {
        this._showRightHandView(
            this.controller.view.toggleEmailSettingsButton,
            this.controller.view,
            ReaderEmailSettingsController
        );
    }

    showAudience() {
        this._showRightHandView(
            this.controller.view.toggleAudienceButton,
            this.controller.view,
            ReaderAudienceController
        );
    }

    showAudiencePrivacy() {
        const configuration = readerConfigurationsInstance.get(this.options.configurationId);
        configuration.fetch();
        this._showRightHandView(
            this.controller.view.toggleAudiencePrivacyButton,
            this.controller.view,
            ReaderPrivacyController
        );
        this.listenTo(
            this.rightHandController, 'readerPrivacyRerender', this.rerender
        );
        this.listenTo(
          this.rightHandController,
          'readerPrivacyClose',
          this.handleReaderPrivacyClose
        );
    }

    handleReaderPrivacyClose() {
      vent.trigger('showFirstReaderSettings');
    }

    close() {
        if (this.controller && this.controller.view) {
            this.controller.view.close();
        }
    }

    _showPlaceHolder() {
        const placeholder = new ReaderPlaceholderView();
        this.showInRightColumn(placeholder);
    }

    _showEditReaderCategory(category) {
        this.controller.deselectNotificationConfigurations();
        var editReaderCategoryController = new EditReaderCategoryController(
            { category: category }
        );
        this.listenTo(
            editReaderCategoryController, 'topicSelection:error', () => {
                const configuration = readerConfigurationsInstance.get(this.options.configurationId);
                configuration.fetch();
            }
        );

        this.showInRightColumn(editReaderCategoryController.view);

        vent.trigger('layout:focus-right');
    }

    _showEditMediaInsight(selectedMediaInsight) {
        const { controller, options: { configurationId } } = this;
        this.showInRightColumn(
            new ReactWrapper({getComponent: () => (
                <MediaInsightsDetail
                    configurationId={configurationId}
                    mediaInsight={selectedMediaInsight}
                    reloadLeftPanel={(selectedId) => controller.showInsights(selectedId)}
                />
            )})
        );
        vent.trigger('layout:focus-right');
    }

    _reloadNotificationConfiguration(notificationConfiguration, error, debugParams, selectedTriggerIds) {
        if (this.reloadInProgress) { return }
        this.reloadInProgress = true;
        const key =
            'webapp.notifications.error.not_saved';
        Notificator.showErrorNotification(I18n.t(key));

        const configuration =
              readerConfigurationsInstance.get(this.options.configurationId);
        configuration.fetch().fail(() => {
            this._reloadFailed();
        }).done(() => {
            configuration.initialize();
            this.show();
            const nc =
                  configuration
                  .notification_configurations
                  .findWhere({id: notificationConfiguration.get('id') })
            if (nc) {
                // reselect the previously selected notificationConfiguration
                this.controller._onRender({ selectedObject: nc });
                // reopen previously selected notificationConfiguration
                this._showEditReaderNotificationConfiguration(nc, selectedTriggerIds);
            } else {
                this._reloadFailed();
            }
        }).always(() => {
            setTimeout(() => { this.reloadInProgress = false; }, 2000);
            throw(error.status + ": " + error.statusText + ', params: ' +
                  JSON.stringify(debugParams));
        });
    }

    _reloadFailed() {
        const key = 'webapp.notifications.error.reload_failed';
        Notificator.showErrorNotification(I18n.t(key));
        this._showPlaceHolder();
    }

    _showEditReaderNotificationConfiguration(notificationConfiguration, selectedTriggerIds) {
        this.controller.deselectCategories();
        const configuration =
              readerConfigurationsInstance.get(this.options.configurationId);
        const controller = new ReaderNotificationConfigurationController({
            configuration: configuration,
            notificationConfiguration: notificationConfiguration,
            selectedTriggerIds: selectedTriggerIds
        })

        this.showInRightColumn(controller.view);

        this.listenTo(
            controller,
            'reader-notification-configuration-reload',
            this._reloadNotificationConfiguration
        );
        this.listenTo(
            controller,
            'reader-notification-configuration-closed',
            this._showPlaceHolder
        );
    }
}
