import React, { useState, useMemo } from 'react';
import SortableButtonList from '../../components/sortableButtonList';
import _ from 'underscore';
import I18n from 'i18n';

export default function MediaInsights(props) {
  const { onSelection, initialInsights, configurationId, selectedId } = props;

  const [insights, setInsights] = useState(initialInsights);
  const [selectedInsightId, setSelectedInsightId] = useState(selectedId);

  function handleInsightClick(insight) {
    onSelection(insight);
    setSelectedInsightId((prevId) =>
      prevId === insight.id ? null : insight.id
    );
  }

  function handleInsightClose() {
    setSelectedInsightId(null);
  }

  function handleSortableUpdate({ source, destination }) {
    if (!destination || source.index === destination.index) return;

    setInsights((prevInsights) => {
      const newInsights = [...prevInsights];
      const [reorderedItem] = newInsights.splice(source.index, 1);
      newInsights.splice(destination.index, 0, reorderedItem);
      setSelectedInsightId(reorderedItem.id);
      return newInsights.map((insight, index) => ({
        ...insight,
        position: index,
      }));
    });
  }

  function handleAddNewInsight() {
    const newInsight = {title: "New Insight", id: null};
    handleInsightClick(newInsight);
  }

  const modifiedInsights = useMemo(() => {
    return insights
      .sort((a, b) => a.position - b.position)
      .map((insight) => ({
        ...insight,
        get: (key) => insight[key],
        className: insight.id === selectedInsightId ? 'is-active' : '',
      }));
  }, [insights, selectedInsightId]);

  const insightProps = {
    sortableEnabled: true,
    selectedObject:
      modifiedInsights.find((insight) => insight.id === selectedInsightId) ||
      null,
    sortableListItems: modifiedInsights,
    sortableGroup: 'MediaInsights',
    listItemName: 'title',
    listItemObjectId: 'id',
    listItemPosition: 'position',
    listItemObjectNamePostfix: () => {
      return '';
    },
    header: I18n.t('webapp.reader_admin.edit.media_insights.header'),
    subText: 'beta',
    addObjectText: I18n.t(
      'webapp.reader_admin.edit.media_insights.add_media_insights'
    ),
    onObjectClick: handleInsightClick,
    onObjectClose: handleInsightClose,
    onAddNewObject: handleAddNewInsight,
    onSortableUpdate: handleSortableUpdate,
  };

  return <SortableButtonList {...insightProps} />;
}
