import Handlebars from 'handlebars';
import React, { useState } from 'react';
import Select2Component from "components/select2Component";
import agents from 'collections/agentsInstance';
import tags from 'collections/tagsInstance';
import manualTags from 'collections/manualTagsInstance';
import Spinner from 'components/Spinner.tsx';
import _ from 'underscore';
import axios from 'axios';

const { t } = Handlebars.helpers;
const types = _.map(['Overview', 'Resonance'], (name, i) => ({id: i + 1, text: name}));
const lookupType = (typeId) => _.find(types, (element) => element.id == typeId);
const lookupTypeId = (str) => {
  str = str || '';
  const element = _.find(types, (t) => t.text.toLowerCase() == str);
  if (element) return element.id;
}
const modelsToOptions = (collection, type, icon) => _.map(
  collection.models,
  (model) => ({type: type, icon: icon, text: model.attributes.name, id: model.id})
);

const selectAgentsAndTags = (mediaInsight) => {
  return [].concat(
    _.reduce(mediaInsight.agents, (memo, agent) => {
      if (!agent.shared) return memo; // do not allow agents owned privately by current user

      memo.push({id: agent.id, text: agent.name, type: 'agent', icon: 'searchagent'});
      return memo;
    }, []),
    _.reduce(mediaInsight.tags, (memo, tag) => {
      memo.push({id: tag.id, text: tag.name, type: 'tag', icon: tag.kind == 'manual' ? 'tag' : 'smarttag'});
      return memo;
    }, []),
  );
}

export default function(props) {
  const { mediaInsight, configurationId, reloadLeftPanel } = props;

  const selectables = [
    {text: 'Agent', icon: 'searchagent', children: modelsToOptions(agents, 'agent', 'searchagent')},
    {text: 'Smarttag', icon: 'smarttag', children: modelsToOptions(tags, 'tag', 'smarttag')},
    {text: 'Tag', icon: 'tag', children: modelsToOptions(manualTags, 'tag', 'tag')},
  ];

  const [syncing, setSyncing] = useState(false);
  const [error, setError] = useState(null);
  const [type, setType] = useState(lookupTypeId(mediaInsight.type) || null);
  const [id, setId] = useState(mediaInsight.id || null);
  const [selection, setSelection] = useState(selectAgentsAndTags(mediaInsight));
  const [title, setTitle] = useState(mediaInsight.title || '');
  const [updateTimeout, setUpdateTimeout] = useState(null);
  const [deleted, setDeleted] = useState(false);

  const axiosErrorProcessing = (error) => {
    const { response } = error;
    if (response) {
      if (response.data.error) {
        setError(response.data.error);
      } else {
        setError(`unknown error ${JSON.stringify(response)}`);
      }
    } else {
      setError(`network failure`);
    }
    setSyncing(false);
  }

  const create = (typeId) => {
    setSyncing(true);
    setType(typeId);
    axios.post(`/api/reader_insights/${configurationId}/v1`, {type: lookupType(typeId).text.toLowerCase()}).then((res) => {
      setSyncing(false);
      setId(res.data.id);
      // immediately send rest of the data for update when created
      update(title, selection, res.data.id);
    }).catch(axiosErrorProcessing);
  }

  const update = (changedTitle, changedSelection, id) => {
    const tagIds = _.reduce(changedSelection, (memo, element) => {
      if (element.type != 'tag') return memo;
      memo.push(element.id);
      return memo;
    }, []);
    const agentIds = _.reduce(changedSelection, (memo, element) => {
      if (element.type != 'agent') return memo;
      memo.push(element.id);
      return memo;
    }, []);
    axios.put(`/api/reader_insights/${configurationId}/v1/${id}`, {title: changedTitle, tag_ids: tagIds, agent_ids: agentIds}).then((res) => {
      setSyncing(false);
      reloadLeftPanel(id);
    }).catch(axiosErrorProcessing);
  }

  const apiDelete = (deletingId) => {
    setSyncing(true);
    axios.delete(`/api/reader_insights/${configurationId}/v1/${deletingId}`).then((res) => {
      setDeleted(true);
      setSyncing(false);
      reloadLeftPanel();
    }).catch(axiosErrorProcessing);
  }

  const select2Props = {
    data: selectables,
    multiple: true,
    inputName: 'selectors',
    select2Id: 'select2_selectors_input',
    preselectedItems: selection,
    selectionCallback: (_, changedSelection) => {
      setSyncing(true);
      setSelection(changedSelection);
      if (!id) {
        setSyncing(false);
      } else {
        update(title, changedSelection, id);
      };
    },
  }

  const changeTitle = (e) => {
    const { target: { value } } = e;
    setTitle(value);
    clearTimeout(updateTimeout);
    setUpdateTimeout(
      setTimeout(() => {
        setSyncing(true);
        if (!id) {
          setSyncing(false);
        } else {
          update(value, selection, id);
        };
      }, 600
    ));
  }

  const typeControl = type ?
    <input name="type" type="text" className="text-input text-input--block" disabled="disabled" value={lookupType(type).text} /> :
    <Select2Component
      selectionCallback={create}
      dropdownCssClass="no-search"
      select2Id="select2_type_input"
      inputName="type"
      data={types}
    />;


  if (deleted) return (
    <div className="lightened-container lifted-container pts phl pbl">
      <h2 className="heading-xlarge mvl">
        { t('webapp.reader_admin.edit.media_insights.headline') }
      </h2>
      <p><i className="icon-trash"></i> { t('webapp.reader_admin.edit.media_insights.deletion_success') }</p>
    </div>
  );

  return (
    <div className="lightened-container lifted-container pts phl pbl">
      <h2 className="heading-xlarge mvl">
        { t('webapp.reader_admin.edit.media_insights.headline') }
      </h2>
      { syncing && <Spinner/> || (<>
        <div className="form-item mbs">
          <label htmlFor="type" className="form-item__label">
            { t('webapp.reader_admin.edit.media_insights.type') }
          </label>
          { typeControl }
        </div>
        { id && (
          <div className="form-item mbs">
            <label htmlFor="title" className="form-item__label">
              { t('webapp.reader_admin.edit.media_insights.title') }
            </label>
            <input className="text-input text-input--block" type="text" value={title} onChange={changeTitle} name="title"/>
          </div>
        )}
        { id && lookupType(type).text != 'Overview' && (
          <div className="form-item mbs">
            <label htmlFor={select2Props.inputName} className="form-item__label">
              { t('webapp.reader_admin.edit.media_insights.selectors') }
            </label>
            <Select2Component {...select2Props} />
          </div>
        )}
        { id && (
          <>
            <hr className="separator mvl"/>
            <button onClick={() => apiDelete(id)} className="button button--block button--negative">
              <i className="icon-trash" style={ {'marginRight': '3.5px'} }></i>
              { t('webapp.reader_admin.edit.media_insights.delete') }
            </button>
          </>
        )}
      </>)}
    </div>
  );
}
